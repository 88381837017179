import { render, staticRenderFns } from "./ReturnRate.vue?vue&type=template&id=5cb7c0c6&scoped=true&"
import script from "./ReturnRate.vue?vue&type=script&lang=ts&"
export * from "./ReturnRate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../skyway-iscritto-webapp/src/webapp-login/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb7c0c6",
  null
  
)

export default component.exports