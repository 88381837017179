var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.xlateLoaded)?_c('b-card',{staticClass:"sh__personalContactValidator",attrs:{"no-body":""}},[_c('b-card-body',[_vm._l(([_vm.buildWizard('personalContactValidator',_vm.steps)]),function(wizard,index){return _c('pnet-wizard',{key:index,attrs:{"name":wizard.name,"steps":wizard.steps,"type":"simple"},scopedSlots:_vm._u([{key:"pnet-wizard-header",fn:function(ref){
var currentstep = ref.currentstep;
return [_c('h5',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t(_vm.getStepDescription(wizard,currentstep)))}})]}},(_vm.type=='EP')?{key:"emailDataStep",fn:function(ref){
var step = ref.step;
return [_c('pnet-validation-observer',{key:"emailDataStep-form",attrs:{"observer-identifier":"emailDataStep-form"}},[_c('b-form',{attrs:{"novalidate":"novalidate","autocomplete":_vm.AUTOCOMPLETE_STATUS},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.pneti18n.$t('nominativo.denEmail.label'),"label-for":"emailPersonale","label-class":{required:true}}},[_c('b-form-input',{attrs:{"type":"text","id":"emailPersonale","name":"emailPersonale","placeholder":_vm.pneti18n.$t('nominativo.denEmail.label'),"rules":{required:true,email:true},"autocomplete":"on"},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1),_c('b-form-group',{attrs:{"label":_vm.pneti18n.$t('nominativo.denEmailConfirm.label'),"label-for":"denEmailConfirm","label-class":{required:true}}},[_c('b-form-input',{attrs:{"type":"text","id":"denEmailConfirm","name":"denEmailConfirm","placeholder":_vm.pneti18n.$t('nominativo.denEmailConfirm.label'),"rules":{required:true,email:true}},model:{value:(_vm.denEmailConfirm),callback:function ($$v) {_vm.denEmailConfirm=$$v},expression:"denEmailConfirm"}}),(_vm.internalValue != _vm.denEmailConfirm && !_vm.isNullOrEmpty(_vm.denEmailConfirm) )?_c('p',{staticClass:"email-match-error"},[_c('br'),_vm._v(_vm._s(_vm.pneti18n.$t('nominativo.matchingError.label')))]):_vm._e()],1)],1)],1)]}}:null,(_vm.type=='CE')?{key:"mobilePhoneDataStep",fn:function(ref){
var step = ref.step;
return [_c('pnet-validation-observer',{key:"mobilePhoneDataStep-form",attrs:{"observer-identifier":"mobilePhoneDataStep-form"}},[_c('b-form',{attrs:{"novalidate":"novalidate","autocomplete":_vm.AUTOCOMPLETE_STATUS},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.pneti18n.$t('nominativo.numCellulare.label'),"label-for":"numeroCellulare","label-class":{required:true}}},[_c('pnet-phone',{attrs:{"initialCountryCode":_vm.BROWSER_LOCALE[1],"id":"numeroCellulare","name":"numeroCellulare","countries":_vm.config.countriesAndPhonePrefix,"rules":{required:true}},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})],1)],1)],1)]}}:null,{key:"validationCode",fn:function(ref){
var step = ref.step;
return [_c('pnet-validation-observer',{key:"validationCode-form",attrs:{"observer-identifier":"validationCode-form"}},[_c('h5',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t(("nominativo.wizard." + _vm.stepName + "Validation.description"), [_vm.internalValue]))}}),(_vm.timesUp)?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.sendValidationCode()}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.resendCode')))]):_c('BaseTimer',{attrs:{"timeLimit":30},on:{"timesUp":function($event){_vm.timesUp=true}}}),_c('br'),_c('b-form',{attrs:{"novalidate":"novalidate","autocomplete":_vm.AUTOCOMPLETE_STATUS},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.pneti18n.$t('nominativo.validationCode.label'),"label-for":"validationCode","label-class":{required:true}}},[_c('b-form-input',{ref:"validationCode",refInFor:true,staticClass:"number text-left",attrs:{"autocomplete":"one-time-code","type":"number","id":"validationCode","name":"validationCode","rules":{required:true,digits:5},"maxlength":"5"},on:{"keydown":function($event){return _vm.maxlengthCheck('validationCode', $event)}},model:{value:(_vm.validationCode),callback:function ($$v) {_vm.validationCode=$$v},expression:"validationCode"}})],1)],1)],1)]}},{key:"pnet-wizard-footer",fn:function(ref){
var currentstep = ref.currentstep;
return [_c('b-button-toolbar',{attrs:{"justify":""}},[_c('div',{staticClass:"float-left"},[(currentstep>0)?_c('b-button',{staticClass:"icon--left",class:_vm.config.lookAndFeel.icons.back,attrs:{"variant":"success","disabled":!_vm.checkBackStepCondition(wizard,currentstep)},on:{"click":function($event){return _vm.handleBackStep(wizard,currentstep)}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.previous')))]):_vm._e()],1),_c('div',{staticClass:"float-right"},[(!_vm.isLastStep(wizard,currentstep))?_c('b-button',{class:_vm.config.lookAndFeel.icons.forward,attrs:{"variant":"success","disabled":!_vm.checkNextStepCondition(wizard,currentstep)},on:{"click":function($event){return _vm.handleNextStep(wizard,currentstep)}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.next')))]):_c('b-button',{attrs:{"variant":"success","disabled":!_vm.checkNextStepCondition(wizard,currentstep)},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.confirm')))])],1)])]}}],null,true)})}),_c('p',{staticStyle:{"margin-top":"20px"},domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t('common.labels.requiredFields'))}})],2),(_vm.serverError)?_c('b-card-footer',[_c('ul',_vm._l((_vm.errorMessages),function(message,index){return _c('li',{key:index},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t(message,[_vm.user.mailtoSubject]))}})])}),0)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }