import { render, staticRenderFns } from "./ContactCentreImpersonator.vue?vue&type=template&id=ba9ed282&scoped=true&"
import script from "./ContactCentreImpersonator.vue?vue&type=script&lang=ts&"
export * from "./ContactCentreImpersonator.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../skyway-iscritto-webapp/src/webapp-login/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba9ed282",
  null
  
)

export default component.exports