import { render, staticRenderFns } from "./CalendarDate.vue?vue&type=template&id=68554264&scoped=true&"
import script from "./CalendarDate.vue?vue&type=script&lang=ts&"
export * from "./CalendarDate.vue?vue&type=script&lang=ts&"
import style0 from "./CalendarDate.vue?vue&type=style&index=0&id=68554264&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../skyway-iscritto-webapp/src/webapp-login/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68554264",
  null
  
)

export default component.exports