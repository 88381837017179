import { render, staticRenderFns } from "./UserPositionsSummary.vue?vue&type=template&id=6aeb05d0&scoped=true&"
import script from "./UserPositionsSummary.vue?vue&type=script&lang=ts&"
export * from "./UserPositionsSummary.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../skyway-iscritto-webapp/src/webapp-login/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aeb05d0",
  null
  
)

export default component.exports