var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.xlateLoaded && _vm.allGreen)?_c('section',{staticClass:"sh__changeSecurityQuestions"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center no-gutters"},[_c('div',[_c('h2',{attrs:{"id":"pageTitle"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t('changeSecurityQuestions.wizard.title'))}})]),_c('b-card',{staticClass:"showAsContainer",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"card-content"},[_vm._l(([_vm.buildWizard('changeSecurityQuestions',_vm.steps)]),function(wizard,index){return _c('pnet-wizard',{key:index,attrs:{"name":wizard.name,"steps":wizard.steps,"type":"simple"},scopedSlots:_vm._u([{key:"pnet-wizard-header",fn:function(ref){
var currentstep = ref.currentstep;
return [_c('h5',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t(_vm.steps[currentstep].description))}})]}},{key:"securityQuestionsStep",fn:function(ref){
var step = ref.step;
return [_c('pnet-validation-observer',{key:"securityQuestions-form",attrs:{"observer-identifier":"securityQuestions-form"}},[_c('b-form',{attrs:{"novalidate":"novalidate","autocomplete":_vm.AUTOCOMPLETE_STATUS},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('QuestionResponseGroup',{attrs:{"id":"questionResponseGroup","options":_vm.questionario.listaDomande,"required":true,"hideAnswers":true},model:{value:(_vm.selectedQuestions),callback:function ($$v) {_vm.selectedQuestions=$$v},expression:"selectedQuestions"}})],1)],1)]}},{key:"otpStep",fn:function(ref){
var step = ref.step;
return [_c('pnet-validation-observer',{key:"otp-form",attrs:{"observer-identifier":"otp-form"}},[_c('b-form',{attrs:{"novalidate":"novalidate","autocomplete":_vm.AUTOCOMPLETE_STATUS},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.pneti18n.$t('changeSecurityQuestions.codOtp.step.label'),"label-for":"codOtp","label-class":{required:true}}},[_c('b-form-input',{ref:"codOtp",refInFor:true,staticClass:"number",attrs:{"autocomplete":"one-time-code","type":"number","id":"codOtp","name":"codOtp","rules":{required:true,digits:6},"maxlength":"6"},on:{"keydown":function($event){return _vm.maxlengthCheck('codOtp', $event)}},model:{value:(_vm.registration.codOtp),callback:function ($$v) {_vm.$set(_vm.registration, "codOtp", $$v)},expression:"registration.codOtp"}}),_c('br'),(_vm.config.features.otpCodeViaSMSButtonLoginActivated)?_c('b-button',{ref:"OTPSMSbtn",refInFor:true,class:"float-right",style:('line-height: 0px'),attrs:{"id":"OTPSMSbtn","variant":"link"},on:{"click":function($event){return _vm.sendOtpCodeSMS()}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.send.otp.code.login')))]):_vm._e()],1)],1)],1)]}},{key:"pnet-wizard-footer",fn:function(ref){
var currentstep = ref.currentstep;
return [_c('b-button-toolbar',{attrs:{"justify":""}},[_c('span',{staticClass:"float-left"},[(currentstep>0)?_c('b-button',{staticClass:"icon--left",class:_vm.config.lookAndFeel.icons.back,attrs:{"variant":"success","disabled":!_vm.checkBackStepCondition(wizard,currentstep)},on:{"click":function($event){return _vm.handleBackStep(wizard,currentstep)}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.previous')))]):_vm._e()],1),_c('span',{staticClass:"float-right"},[(!_vm.isLastStep(wizard,currentstep))?_c('b-button',{class:_vm.config.lookAndFeel.icons.forward,attrs:{"variant":"success","disabled":!_vm.checkNextStepCondition(wizard,currentstep)},on:{"click":function($event){return _vm.handleNextStep(wizard,currentstep)}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.next')))]):_c('b-button',{attrs:{"variant":"success","disabled":!_vm.checkNextStepCondition(wizard,currentstep)},on:{"click":function($event){return _vm.handleNextStep(wizard,currentstep)}}},[_vm._v(_vm._s(_vm.pneti18n.$t('common.buttons.confirm')))])],1)])]}}],null,true)})}),_c('p',{staticStyle:{"margin-top":"20px"},domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t('common.labels.requiredFields'))}})],2),(_vm.serverError)?_c('b-card-footer',[_c('ul',[_c('li',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t('validation.otp.invalid'))}})])])]):_vm._e()],1)],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }