var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.xlateLoaded)?_c('section',{staticClass:"sh__nominativo"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center no-gutters"},[_c('div',{class:_vm.config.lookAndFeel.pageWidth},[_c('h2',{attrs:{"id":"pageTitle"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t('main.payrollDetails'))}})]),_c('b-card',{staticClass:"mb-3"},[_c('div',{staticClass:"row"},[_c('StackedsTable',{staticClass:"col-md-6",attrs:{"title":_vm.pneti18n.$t('main.companyDetails.label'),"data":_vm.getStackedData(_vm.distributore,{
								denDistributore: {label:'main.companyName'},
								codFiscale: {label:'main.taxNumber.label'}
							})}}),_c('StackedsTable',{staticClass:"col-md-6",attrs:{"title":_vm.pneti18n.$t('main.membershipDetails.label'),"data":_vm.getStackedData(_vm.distributore,{
								dataIscrizione: {label:'main.dateJoining.label',formatter:_vm.getFormattedCETDate},
								codDistributore: {label:'main.idCode.label'}
							})}})],1)]),_c('b-card',[_c('div',{staticClass:"row"},[_c('StackedsTable',{staticClass:"col-md-6",attrs:{"title":_vm.pneti18n.$t('main.contactDetails.label'),"data":_vm.getStackedData(_vm.distributore,{
								denIndirizzo: {label:'main.address.label'},
								codCap: {label:'main.zipCode.label'},
								denLocalita: {label:'main.city.label'},
								codNazione: {label:'main.country.label'}
							})}}),_c('StackedsTable',{staticClass:"col-md-6",attrs:{"titleInvisible":"true","data":_vm.getStackedData(_vm.distributore,{
								numTelefono: {label:'main.phoneNumber.label'},
								denEmail: {label:'main.email.label'},
								denReferente: {label:'main.contactPerson.label'}
							})}})],1)])],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }