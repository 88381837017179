var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.xlateLoaded)?_c('section',{staticClass:"sh__nominativo"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center no-gutters"},[_c('div',{class:_vm.config.lookAndFeel.pageWidth},[_c('h2',{attrs:{"id":"pageTitle"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pneti18n.$t('nominativo.myEmployers.title.label'))}})]),_vm._l((_vm.partTimeNazione),function(ppn,nazione){return _c('div',{key:nazione,staticClass:"row b-card-profile-section"},[_c('div',{staticClass:"col-sm-12 col-md-12"},[_c('h3',[_vm._v(_vm._s(_vm.pneti18n.$t('nazioni.label.' + nazione)))]),_vm._l((ppn),function(partTime,index){return _c('div',{key:index,staticClass:"row b-card-profile-section"},[_c('div',{staticClass:"col-md-12"},[_c('b-card',[_c('div',{staticClass:"row"},[_c('StackedsTable',{staticClass:"col-md-6",attrs:{"title":partTime.istituzioneDenRagioneSociale,"data":_vm.getStackedData(partTime,{
												istituzioneCodFiscale: {label:'nominativo.myEmployers.employerTaxNumber.label'},
												recapitoIstituzioneCodNazione: {label:'nominativo.myEmployers.employerLegalAddress.label',formatter:function (value) {return ((partTime.recapitoIstituzioneDenIndirizzo || '') + "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" + (partTime.recapitoIstituzioneDenNumeroCivico || '') + "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" + (partTime.recapitoIstituzioneDenLocalita || '') + "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" + (_vm.pneti18n.$t('nazioni.label.' + value)));}},
												recapitoIstituzioneDenReferente: {label:'nominativo.myEmployers.employerRapresentative.label'},
												recapitoIstituzioneDenEmail: {label:'nominativo.myEmployers.employerEmail.label'},
												recapitoIstituzioneNumTelefono: {label:'nominativo.myEmployers.employerPhoneNumber.label'}
											})}}),_c('StackedsTable',{staticClass:"col-md-6",attrs:{"title":_vm.pneti18n.$t('nominativo.myEmployers.employerDetail.title.label'),"data":_vm.getStackedData(partTime,{
												indStatoIscrizione: {label:'nominativo.myEmployers.participantContributionStatus.label',formatter:function (value) {return _vm.pneti18n.$t(("indStatoIscrizione." + value));}},
												codfiscaleOTaxidentnumb: {label:'nominativo.myEmployers.participantTaxNumber.label'},
												socialsecuritynumber: {label:'main.participantSSN.label'},
												dataInizioCollaborazione: {label:'nominativo.myEmployers.participantDateJoinService.label',formatter:_vm.getFormattedCETDate},
												dataFineCollaborazione: {label:'nominativo.myEmployers.participantDateLeaveService.label',formatter:_vm.getFormattedCETDate},
												posizioneDataPrimaIscrizione: {label:'nominativo.myEmployers.dataPrimaIscrizione.label',formatter:_vm.getFormattedCETDate}
											})}})],1)])],1)])})],2)])})],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }