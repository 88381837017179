import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=069d76b6&scoped=true&"
import script from "./Documents.vue?vue&type=script&lang=ts&"
export * from "./Documents.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../skyway-iscritto-webapp/src/webapp-login/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069d76b6",
  null
  
)

export default component.exports