import { render, staticRenderFns } from "./PdfDwUp.vue?vue&type=template&id=4efdb827&scoped=true&"
import script from "./PdfDwUp.vue?vue&type=script&lang=ts&"
export * from "./PdfDwUp.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../skyway-iscritto-webapp/src/webapp-login/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efdb827",
  null
  
)

export default component.exports